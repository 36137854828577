import { useMemo } from 'react';
import { useMessages } from '../../store/messages';
import { useObservations } from '../../store/observations';
import DebriefingSection from '../layout/debriefing-section';
import { scenarioLabels } from '../plots/scenario-labels';
import StepsQuality from '../plots/steps-quality';

interface IProps {
  sessionId: string;
  groupId: string;
}

const StepsQualitySection = ({ sessionId, groupId }: IProps) => {
  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const planObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/${groupId}/plans/executive`),
    [find, observations, sessionId, groupId]
  );

  const t = useMessages(({ print }) => print);

  if (!groupId) return <p>No team selected.</p>;

  const groupObservation = planObservations.find(
    obs => obs.groupId === groupId
  );

  return (
    <DebriefingSection
      title={t('slide-8-title')}
      data={`${scenarioLabels[
        groupObservation?.scenario
      ]?.toUpperCase()} - AcR: ${(
        Number(groupObservation?.acceptanceRate) * 100
      ).toFixed(2)}%`}
      subTitle={t('slide-8-tooltip')}
    >
      <StepsQuality
        groupId={groupId}
        preparation={
          Number(planObservations[0]?.preparationPhasePerformance || 0) * 100
        }
        communication={
          Number(planObservations[0]?.communicationPhasePerformance || 0) * 100
        }
        execution={
          Number(planObservations[0]?.executionPhasePerformance || 0) * 100
        }
      />
    </DebriefingSection>
  );
};

export default StepsQualitySection;
