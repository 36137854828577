import clsx from 'clsx';
// import { Switch } from '@headlessui/react';

// const _Switch = ({ label, isEnabled, handleSwitch }) => (
//   <Switch.Group className="flex flex-row items-center justify-start
//   space-x-4">
//     <Switch
//       checked={isEnabled}
//       onChange={handleSwitch}
//       className={`${
//         isEnabled ? 'bg-blue-600' : 'bg-gray-200'
//       } relative inline-flex h-6 w-11 items-center rounded-full`}
//     >
//       <span className="sr-only">{label}</span>
//       <span
//         className={`${
//           isEnabled ? 'translate-x-6' : 'translate-x-1'
//         } inline-block h-4 w-4 transform rounded-full bg-white`}
//       />
//     </Switch>
//     <Switch.Label>{label}</Switch.Label>
//   </Switch.Group>
// );

// export default _Switch;

const _Switch = ({
  title,
  description,
  isEnabled,
  handleSwitch,
  hide = false,
  handleHover = () => {},
  handleLeave = () => {},
}) => (
  <div
    className={clsx(
      'flex flex-row items-center justify-start w-52 xl:w-60 3xl:w-64 space-x-3 cursor-pointer group',
      hide && 'opacity-50'
    )}
    onClick={() => handleSwitch(!isEnabled)}
    onMouseOver={() => handleHover()}
    onMouseLeave={() => handleLeave()}
  >
    <span
      className={clsx(
        'ring-offset-4 ring-offset-white ring-2 ring-sky-700 group-active:ring-offset-2 block relative w-3 h-3 rounded-full flex-none transition-all duration-200 ease-in-out',
        isEnabled ? 'bg-blue-400' : 'bg-white'
      )}
    />

    <span>
      <p className="text-xs font-bold text-slate-700 uppercase">{title}</p>
      <p className="text-xxs xl:text-xs leading-tight font-light text-slate-800">
        {description}
      </p>
    </span>
  </div>
);

export default _Switch;
