import { motion } from 'framer-motion';

export const Shape = (props: {
  scenario: 'neutral' | 'crisis';
  x: number;
  y: number;
  groupId: string;
  isBenchmark?: boolean;
  isOwnGroup?: boolean;
  distinguishScenarios?: boolean;
  handleClick?: (...args: any[]) => void;
  label?: { part1: string; part2?: string };
}) => (
  <g>
    <defs>
      <linearGradient id="own-group" x1="0%" y1="0%" x2="100%" y2="20%">
        <stop offset="0%" style={{ stopColor: '#6ABACD', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#6A7ACD', stopOpacity: 1 }} />
      </linearGradient>

      <linearGradient id="gradient-general" x1="0%" y1="0%" x2="100%" y2="20%">
        <stop offset="0%" style={{ stopColor: '#6A7ACD', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#6A5ACD', stopOpacity: 1 }} />
      </linearGradient>
      <linearGradient id="gradient-normal" x1="0%" y1="0%" x2="100%" y2="20%">
        <stop offset="0%" style={{ stopColor: '#5A8Aff', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#2A6Aff', stopOpacity: 1 }} />
      </linearGradient>
      <linearGradient id="gradient-crisis" x1="0%" y1="0%" x2="100%" y2="20%">
        <stop offset="0%" style={{ stopColor: '#a285cf', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#a244cf', stopOpacity: 1 }} />
      </linearGradient>
    </defs>

    {props.scenario === 'neutral' || !props.distinguishScenarios ? (
      <motion.circle
        onClick={() => props.handleClick && props.handleClick()}
        whileHover={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        height={42}
        width={44}
        cx={props.x + 4}
        cy={props.y + 2}
        r={32}
        fill={
          props.isBenchmark
            ? 'rgb(194 65 12)'
            : props.isOwnGroup
            ? 'url(#own-group)'
            : props.distinguishScenarios
            ? props.scenario === 'neutral'
              ? 'url(#gradient-normal)'
              : 'url(#gradient-crisis)'
            : 'url(#gradient-general)'
        }
        strokeDasharray={props.isBenchmark ? '3 3' : '0'}
        stroke={props.isBenchmark ? 'rgb(194 65 12)' : 'SlateBlue'}
        strokeWidth={props.isBenchmark ? 4 : props.isOwnGroup ? 2 : 0}
        style={{
          filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
          cursor: props.handleClick ? 'pointer' : 'default',
        }}
      />
    ) : (
      <motion.rect
        onClick={() => props.handleClick && props.handleClick()}
        whileHover={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        height={64}
        width={64}
        rx={8}
        x={props.x - 30}
        y={props.y - 30}
        fill={
          props.isBenchmark
            ? 'rgb(194 65 12)'
            : props.isOwnGroup
            ? 'url(#own-group)'
            : props.distinguishScenarios
            ? 'url(#gradient-crisis)'
            : 'url(#gradient-normal)'
        }
        strokeDasharray={props.isBenchmark ? '3 3' : '0'}
        stroke={props.isBenchmark ? 'rgb(194 65 12)' : 'SlateBlue'}
        strokeWidth={props.isBenchmark ? 4 : props.isOwnGroup ? 2 : 0}
        style={{
          filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
          cursor: props.handleClick ? 'pointer' : 'default',
        }}
      />
    )}

    <text
      onClick={() => props.handleClick && props.handleClick()}
      x={props.x + 4}
      y={props.y + 48}
      fill={
        props.isBenchmark
          ? 'rgb(194 65 12)'
          : props.isOwnGroup
          ? 'url(#own-group)'
          : props.distinguishScenarios
          ? props.scenario === 'neutral'
            ? 'url(#gradient-normal)'
            : 'url(#gradient-crisis)'
          : 'url(#gradient-general)'
      }
      textAnchor="middle"
      dominantBaseline="middle"
      fontWeight={props.isOwnGroup ? 900 : 600}
      fontFamily="Inter"
      fontSize={props.isOwnGroup ? '1rem' : '0.8rem'}
      style={{
        filter: props.isOwnGroup
          ? 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))'
          : 'drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2))',
      }}
    >
      {props.groupId}
      {props.isOwnGroup && ' (you)'}
    </text>

    {props.label && (
      <>
        <text
          onClick={() => props.handleClick && props.handleClick()}
          x={props.x + 4}
          y={props.y - 8}
          fill="White"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight={400}
          fontFamily="Inter"
          fontSize="0.6rem"
          cursor={props.handleClick ? 'pointer' : 'default'}
        >
          {props.label.part1}
        </text>
        <text
          onClick={() => props.handleClick && props.handleClick()}
          x={props.x + 4}
          y={props.y + 8}
          fill="White"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight={700}
          fontFamily="Inter"
          fontSize="0.8rem"
          cursor={props.handleClick ? 'pointer' : 'default'}
        >
          {props.label.part2}
        </text>
      </>
    )}
  </g>
);
