import clsx from 'clsx';
import { useMessages } from '../../store/messages';
import { scenarioLabels } from './scenario-labels';

const DistanceDispersionFilters = ({ displayedScenarios, handleUpdate }) => {
  const t = useMessages(({ print }) => print);
  return (
    <div className="flex flex-col min-w-max p-4 my-auto ml-4 mr-auto space-y-6 rounded-lg shadow-2xl left-8 bg-white/50 backdrop-blur-sm shadow-black/10">
      <div className="font-bold text-sky-900">{t('filters')}</div>
      <div className="flex flex-col space-y-4">
        {Object.keys(scenarioLabels).map(scenario => (
          <div
            key={scenario}
            className="flex flex-row items-center space-x-2 cursor-pointer"
            onClick={() =>
              handleUpdate(
                !displayedScenarios.includes(scenario)
                  ? [...displayedScenarios, scenario]
                  : displayedScenarios.filter(label => label !== scenario)
              )
            }
          >
            <div
              className={clsx(
                'text-gray-500 border-2 h-6 w-6',
                scenario === 'neutral'
                  ? 'rounded-full border-blue-700'
                  : 'rounded border-purple-700',
                displayedScenarios.includes(scenario) && scenario === 'neutral'
                  ? 'bg-blue-700'
                  : displayedScenarios.includes(scenario) &&
                    scenario === 'crisis'
                  ? 'bg-purple-700'
                  : ''
              )}
              style={{
                filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
              }}
            />
            <span className="text-sm">
              Scenario "{scenarioLabels[scenario]}"
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistanceDispersionFilters;
