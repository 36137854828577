import { useMessages } from '../../store/messages';
import _Switch from '../layout/switch';

const labels = [
  'lossByMissingActions',
  'lossByMissingPUActions',
  'lossByMissingPEOUAction',
  'lossByNotFittingActions',
  'lossByEntropy',
];

const PerformanceAdjustmentFilters = ({
  hoveredLoss,
  displayedLosses,
  handleUpdate,
  handleHover = (loss: string) => {},
  handleLeave = (loss: string) => {},
}) => {
  const t = useMessages(({ print }) => print);
  return (
    <div className="flex flex-col p-3 xl:p-4 my-auto ml-3 xl:ml-4 mr-auto space-y-5 xl:space-y-8 rounded-lg shadow-2xl left-8 bg-white/40 border-t-2 border-white backdrop-blur-sm shadow-black/5">
      <p className="uppercase text-blue-900/90 text-base xl:text-lg font-light pb-1 xl:pb-2">
        {t('variables')}
      </p>

      {labels.map(labelId => (
        <_Switch
          key={labelId}
          title={t(`${labelId}-title`)}
          description={t(`${labelId}-desc`)}
          isEnabled={displayedLosses.includes(labelId)}
          hide={hoveredLoss && hoveredLoss !== labelId}
          handleHover={() => handleHover(labelId)}
          handleLeave={() => handleLeave(labelId)}
          handleSwitch={(isChecked: boolean) =>
            handleUpdate(draft =>
              isChecked
                ? [...draft, labelId]
                : draft.filter(label => label !== labelId)
            )
          }
        />
      ))}
    </div>
  );
};

export default PerformanceAdjustmentFilters;
