import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import buildAssetPath from '../../utils/buildAssetPath';
import { useFloating } from '@floating-ui/react-dom';

const DebriefingSection = ({ children, title, subTitle, data = null }) => {
  const { x, y, reference, floating, strategy } = useFloating();

  return (
    <InView threshold={0.4} triggerOnce={false}>
      {({ inView, ref }) => (
        <motion.div
          ref={ref}
          className="flex flex-col w-screen h-screen p-4 2xl:p-4 3xl:p-8"
        >
          <div className="w-full flex flex-row space-x-8 relative">
            <motion.h2 className="relative h-16 3xl:h-20 overflow-y-hidden font-black text-2xl 2xl:text-3xl 3xl:text-5xl text-blue-100">
              {title.split('').map((character: string, characterIndex) => (
                <motion.span
                  key={characterIndex}
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
                  transition={
                    inView
                      ? { delay: 0.7 + characterIndex / 40 }
                      : { delay: 0.1 }
                  }
                  className="relative inline-block"
                >
                  {character === ' ' ? (
                    <span className="w-4 3xl:w-5 block" />
                  ) : (
                    character
                  )}
                </motion.span>
              ))}
            </motion.h2>
            <motion.span
              className="relative group"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ delay: 1 }}
            >
              <img
                ref={reference}
                src={buildAssetPath('images/info.png')}
                alt="Info"
                className="w-10 h-10 cursor-help"
              />

              <span
                ref={floating}
                className="bg-black/70 backdrop-blur-lg shadow-lg border-b-2 border-blue-900/50 rounded-lg p-4 pointer-events-none text-white leading-tight text-xs 2xl:text-sm 3xl:text-base w-96 z-50 group-hover:opacity-100 opacity-0 transition-all duration-200 ease-in-out"
                style={{
                  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.4)',
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                {subTitle}
              </span>
            </motion.span>
            <motion.span
              className="h-16 3xl:h-20 overflow-y-hidden font-black text-2xl 2xl:text-3xl 3xl:text-5xl text-blue-100 absolute right-0"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ delay: 1 }}
            >
              {data}
            </motion.span>
          </div>
          <motion.div
            className="w-full flex-grow overflow-y-hidden flex flex-row rounded-xl bg-blue-100 items-center justify-center relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 2, duration: 1 }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </InView>
  );
};

export default DebriefingSection;
