import { StoreSlice } from '../../utils/store-slice';
import {
  createPattern,
  IObservations,
  toObservation,
} from '../concepts/observations';
import { IObservationRegistryApi, ObservationData } from '../remote/api';
import { produce } from 'immer';
import { ifValueOf, is, isEqualTo, where } from '../../../libs/say-it';

interface IPrivate {
  observations: ObservationData[];
}

export const create =
  (registry: IObservationRegistryApi): StoreSlice<IObservations & IPrivate> =>
  (set, get) => {
    return {
      observations: [],

      async load(directoryId, objectId) {
        console.time('find all observations');
        const observations = await registry.find(directoryId, { objectId });
        console.timeEnd('find all observations');

        set(
          produce((s: IPrivate) => {
            s.observations.push(...observations);
          })
        );
      },

      async save(directoryId) {
        await registry.register(
          directoryId,
          get().observations.filter(where('isRegistered', is(false)))
        );
      },

      clear() {
        set(
          produce((s: IPrivate) => {
            s.observations = [];
          })
        );
      },

      find(objectIdPattern) {
        return get()
          .observations.filter(observation =>
            createPattern(objectIdPattern).test(observation.objectId)
          )
          .map(toObservation);
      },

      describe(objectId, demographic, value) {
        set(
          produce((s: IPrivate) => {
            const i = s.observations.findIndex(
              ifValueOf('objectId', isEqualTo(objectId))
            );

            const exists = i >= 0;

            const observation = exists
              ? s.observations[i]
              : createObservationData({ objectId });

            observation.demographics[demographic] = value;

            if (exists) s.observations.splice(i, 1, observation);
            else s.observations.push(observation);
          })
        );
      },

      measure(objectId, metric, value) {
        set(
          produce((s: IPrivate) => {
            const i = s.observations.findIndex(
              ifValueOf('objectId', isEqualTo(objectId))
            );

            const exists = i >= 0;

            const observation = exists
              ? s.observations[i]
              : createObservationData({ objectId });

            observation.metrics[metric] = value;

            if (exists) s.observations.splice(i, 1, observation);
            else s.observations.push(observation);
          })
        );
      },
    };
  };

const createObservationData = (
  partial: Partial<ObservationData> & { objectId: string }
): ObservationData => ({
  isRegistered: false,
  time: 0,
  demographics: {},
  metrics: {},
  observationId: partial.objectId,
  ...partial,
});
