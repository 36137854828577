import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { useObservations } from '../store/observations';
import { useMessages } from '../store/messages';

const TeamSelector = ({ sessionId, ownTeamId, handleTeamSelection }) => {
  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const groups = useMemo(
    () => [
      ...new Set(
        find(`/sessions/${sessionId}/**/groups/**`)
          ?.map(({ groupId }) => groupId)
          .filter(Boolean)
          .filter(groupId => (ownTeamId ? groupId === ownTeamId : groupId))
      ),
    ],
    [find, observations, sessionId, ownTeamId]
  );

  const t = useMessages(({ print }) => print);

  return (
    <motion.div className="flex flex-col items-center justify-center w-screen h-screen gap-10 px-24 py-20 xl:gap-12 2xl:gap-16 3xl:gap-20 2xl:px-28 3xl:px-32">
      <h2 className="text-4xl font-black text-blue-100 xl:text-5xl 2xl:text-6xl 3xl:text-7xl">
        {t('playing-teams')}
      </h2>

      <div className="flex flex-row flex-wrap gap-10 p-2 xl:gap-12 2xl:gap-16 3xl:gap-20">
        {groups.map((team, index) => (
          <motion.div
            key={team}
            className="p-10 text-2xl font-bold border-b border-blue-200/20 text-blue-100/90 text-center flex items-center justify-center w-1/4 rounded-xl bg-blue-400/20 cursor-pointer backdrop-blur-md shadow-xl shadow-black/[0.02] hover:shadow-black/[0.04] active:shadow-black/[0.014]"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1, transition: { delay: 0 } }}
            transition={{ delay: 0.4 + 0.2 * index }}
            whileHover={{ scale: 1.05, transition: { delay: 0 } }}
            whileTap={{ scale: 0.99, transition: { duration: 0.1, delay: 0 } }}
            onClick={() => handleTeamSelection(team)}
          >
            Team {team}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default TeamSelector;
