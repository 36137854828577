import { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import decimalToRoman from '../../utils/decimal-to-roman';
import Card from '../card';
import useMousePosition from '../../../hooks/useMousePosition';
import { useObservations } from '../../../store/observations';
import KOTTER_STEPS from '../../../data/kotter-steps-data';
import ACTIONS from '../../../data/actions';
import IAction from '../../../core/action.interface';
import { useDebriefing } from '../../../store/debriefing';
import { useMessages } from '../../../store/messages';

interface IProps {
  sessionId: string;
  groupId: string;
}

const PlanAnalyzer = ({ sessionId, groupId }: IProps) => {
  const { x: mouseX, y: mouseY } = useMousePosition();

  const [highlightedCategory, setHighlightedCategory] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [displayedDescription, setDisplayedDescription] = useState<string>();

  const { visitedKotterSteps, visitKotterStep } = useDebriefing(
    ({ getVisitedKotterSteps, visitKotterStep }) => ({
      visitedKotterSteps: getVisitedKotterSteps(groupId),
      visitKotterStep,
    })
  );

  const t = useMessages(({ print }) => print);

  const [highlightedOwnAction, setHighlightedOwnAction] = useState<
    IAction & { isInStrategicFit: boolean }
  >();

  useEffect(
    () => description && setDisplayedDescription(description),
    [description]
  );

  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const groupActionsObservations = useMemo(
    () =>
      find(
        `/sessions/${sessionId}/**/groups/${groupId}/plans/executive/actions/**`
      ),
    [find, observations, sessionId, groupId]
  );

  const idealPlan = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/${groupId}/reference-plan/**`),
    [find, observations, sessionId, groupId]
  );

  useEffect(() => setHighlightedCategory(undefined), [groupId]);

  if (!groupId) return <p>No team selected.</p>;

  if (!groupActionsObservations?.length) return <p>No group actions found.</p>;

  const getActionInfo = action =>
    ACTIONS.find(({ id }) => id === action.actionId);

  return (
    <>
      <motion.div
        className="fixed z-50 max-w-lg p-4 leading-tight text-blue-900 shadow-xl pointer-events-none bg-white/40 backdrop-blur-xl rounded-xl"
        animate={{
          x: Math.min(
            Math.max(-(window.innerWidth / 4), mouseX - window.innerWidth / 2),
            window.innerWidth - window.innerWidth / 1.6
          ),
          y: mouseY + 24 - window.innerHeight / 2,
          opacity: description ? 1 : 0,
        }}
      >
        {displayedDescription}
      </motion.div>
      <div className="absolute bg-white/40 flex flex-row items-center space-x-4 px-4 py-2 rounded-xl top-[2px] left-0 text-slate-800/80 right-0 w-1/3 mx-auto">
        <p className="flex-grow ml-auto text-sm font-bold text-left uppercase text-slate-600">
          {t('misc-legend')}
        </p>

        <div className="flex flex-row items-center justify-between">
          <span className="block w-5 h-6 bg-white rounded shadow opacity-50" />
          <p className="ml-4 leading-none text-xxs">
            {t('plan-analyzer-non-selected-actions')}
          </p>
        </div>

        <div className="flex flex-row items-center justify-between">
          <span className="block w-5 h-6 bg-white border-2 border-red-400 rounded shadow" />
          <p className="ml-4 leading-none text-xxs">
            {t('plan-analyzer-non-fitting-actions')}
          </p>
        </div>
      </div>

      <div className="relative flex flex-col justify-center p-3 3xl:p-4">
        <div className="flex flex-col mb-6 space-y-2 2xl:mb-8 3xl:mb-10 4xl:mb-12">
          <div className="flex flex-row flex-wrap justify-center space-x-1 4xl:space-x-2">
            {groupActionsObservations
              .filter(({ planId }) => planId === 'executive')
              ?.sort((a, b) => Number(a.position) - Number(b.position))
              .map((action, index) => (
                <Card
                  key={index}
                  isHighlighted={action.kotterStep === highlightedCategory}
                  isRevealed={visitedKotterSteps.includes(action.kotterStep)}
                  label={decimalToRoman(index + 1)}
                  description={t(`${action.actionId}-name`)}
                  index={index}
                  descriptionOrientation="top"
                  layoutAnimationKey="executive"
                  keepHighlighted
                  handleMouseEnter={() => {
                    setDescription(t(`${action.actionId}-description-rev`));

                    setHighlightedOwnAction({
                      ...getActionInfo(action),
                      isInStrategicFit: !!action.isInStrategicFit,
                    });
                  }}
                  handleMouseLeave={() => {
                    setDescription(undefined);

                    setHighlightedOwnAction(undefined);
                  }}
                  handleTitleMouseEnter={() =>
                    setDescription(t(`${action.actionId}-name`))
                  }
                  outline={
                    visitedKotterSteps?.includes(action.kotterStep as string) &&
                    !action.isInStrategicFit &&
                    'red'
                  }
                />
              ))}
          </div>

          <p className="mx-auto text-sm font-bold text-center uppercase text-slate-400/90">
            {t('misc-your-plan')}
          </p>
        </div>

        <div className="flex flex-col m-4 space-y-2 2xl:m-6 3xl:m-8 4xl:m-10">
          <p
            className={clsx(
              'transition-all duration-500 ease-in-out mx-auto text-center uppercase text-slate-400/90 text-sm font-bold',
              visitedKotterSteps?.length === KOTTER_STEPS.length
                ? 'opacity-100 blur-0'
                : 'opacity-0 blur-lg'
            )}
          >
            {t('plan-analyzer-kotter-model')}
          </p>
          <div className="flex flex-row justify-center space-x-1 4xl:space-x-2">
            {KOTTER_STEPS.map((v, index) => (
              <span
                key={index}
                className={clsx(
                  'px-6 py-1 3xl:py-2 text-sm 3xl:text-lg 4xl:text-xl text-blue-900 rounded transition-all duration-200 ease-in-out cursor-pointer',
                  v.id === highlightedCategory
                    ? 'bg-yellow-300'
                    : visitedKotterSteps?.includes(v.id)
                    ? highlightedOwnAction?.kotterStep === v.id
                      ? 'bg-yellow-300'
                      : 'bg-yellow-100'
                    : 'bg-white'
                )}
                onClick={() => {
                  setHighlightedCategory(draft =>
                    draft === v.id ? undefined : v.id
                  );
                  // setVisitedKotterSteps(draft =>
                  //   draft?.includes(v.id) ? draft : [...(draft || []), v.id]
                  // );
                  visitKotterStep(groupId, v.id);
                }}
              >
                <span className="mr-2 font-bold opacity-40">{index + 1}.</span>
                <span
                  className={clsx(
                    'transition-opacity duration-200 ease-in-out',
                    visitedKotterSteps?.includes(v.id)
                      ? 'opacity-80'
                      : 'opacity-0'
                  )}
                >
                  {v.label}
                </span>
              </span>
            ))}
          </div>
        </div>

        <div className="flex flex-col mt-6 space-y-2 2xl:mt-8 3xl:mt-10 4xl:mt-12">
          <p className="mx-auto text-sm font-bold text-center uppercase text-slate-400/90">
            {t('plan-analyzer-an-ideal-plan')}
          </p>
          <div className="flex flex-row flex-wrap justify-center space-x-1">
            {idealPlan
              ?.sort((a, b) => Number(a.position) - Number(b.position))
              .map((idealAction, index) => (
                <Card
                  key={index}
                  isHighlighted={
                    idealAction.kotterStep.toString() === highlightedCategory ||
                    (visitedKotterSteps.includes(
                      idealAction.kotterStep as string
                    ) &&
                      highlightedOwnAction?.isInStrategicFit &&
                      highlightedOwnAction?.kotterStep ===
                        (idealAction.kotterStep as string))
                  }
                  isRevealed={visitedKotterSteps.includes(
                    idealAction.kotterStep
                  )}
                  label={decimalToRoman(index + 1)}
                  description={t(`${idealAction.actionId}-name`)}
                  index={index}
                  descriptionOrientation="bottom"
                  layoutAnimationKey="ideal"
                  hideWhenIdle
                  isUnused={
                    // Only show unused actions after revealing them.
                    visitedKotterSteps.includes(
                      idealAction.kotterStep as string
                    ) && !idealAction.isUsed
                  }
                  hasBeenRevealed={visitedKotterSteps.includes(
                    idealAction.kotterStep as string
                  )}
                  handleMouseEnter={() =>
                    visitedKotterSteps.includes(
                      idealAction.kotterStep as string
                    ) &&
                    setDescription(t(`${idealAction.actionId}-description`))
                  }
                  handleMouseLeave={() => setDescription(undefined)}
                  handleTitleMouseEnter={() =>
                    setDescription(t(`${idealAction.actionId}-name`))
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanAnalyzer;
