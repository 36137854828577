import clsx from 'clsx';
import { useMessages } from '../../store/messages';

const labels = {
  isPU: 'pu',
  isPEOU: 'peou',
  isInStrictStrategicFit: 'strategic-fit',
};

const LegendIcon = ({ label }: { label: string }) => {
  return label === 'isPU' ? (
    <span className="px-1 py-px text-xs font-bold text-white bg-indigo-400 rounded">
      PU
    </span>
  ) : label === 'isPEOU' ? (
    <span className="px-1 py-px text-xs font-bold text-white bg-blue-400 rounded">
      PEOU
    </span>
  ) : label === 'isInStrictStrategicFit' ? (
    <span className="w-4 h-4 bg-white border-2 border-blue-400 rounded" />
  ) : (
    <span />
  );
};

const Kotter2Filters = ({ activeFilters, handleUpdate }) => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="flex flex-row space-x-6">
      <>
        <div className="flex-grow font-bold text-sky-900">{t('filters')}</div>
        <div className="flex flex-row space-x-8">
          {Object.keys(labels).map(currentLabel => (
            <div
              key={currentLabel}
              className={clsx(
                'flex flex-row items-center space-x-2 cursor-pointer transition-opacity duration-200 ease-in-out',
                activeFilters?.includes(currentLabel)
                  ? 'opacity-100'
                  : 'opacity-40 hover:opacity-60'
              )}
              onClick={() =>
                handleUpdate(
                  !activeFilters?.includes(currentLabel)
                    ? [...activeFilters, currentLabel]
                    : activeFilters?.filter(label => label !== currentLabel)
                )
              }
            >
              <LegendIcon label={currentLabel} />
              <span className="text-sm">{t(labels[currentLabel])}</span>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default Kotter2Filters;
