export default function decimalToRoman(decimal: number): string {
  const romanSymbols: { [key: string]: number } = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  let result: string = '';

  for (const currentSymbol in romanSymbols)
    while (decimal >= romanSymbols[currentSymbol]) {
      result += currentSymbol;
      decimal -= romanSymbols[currentSymbol];
    }

  return result;
}
