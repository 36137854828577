import { useEffect, useState } from 'react';

export default function useKeyPress(targetKeyCode: number) {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  function downHandler({ keyCode }: KeyboardEvent) {
    if (keyCode === targetKeyCode) setKeyPressed(true);
  }

  function upHandler({ keyCode }: KeyboardEvent) {
    if (keyCode === targetKeyCode) setKeyPressed(false);
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
}
