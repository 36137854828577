import { useMemo, useState } from 'react';
import { useObservations } from '../../store/observations';
import DebriefingSection from '../../comps/layout/debriefing-section';
import PerformanceAdjustment from '../../comps/plots/performance-adjustment';
import PerformanceAdjustmentFilters from '../../comps/plots/performance-adjustment-filters';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMessages } from '../../store/messages';

interface IProps {
  sessionId: string;
  ownGroupId?: string;
}

const PerformanceAdjustmentSection = ({ sessionId, ownGroupId }: IProps) => {
  const t = useMessages(({ print }) => print);

  const [areBenchmarksVisible, setBenchmarksVisible] = useState<boolean>(false);

  const [displayedLosses, setDisplayedLossed] = useState<string[]>([]);
  const [hoveredLoss, setHoveredLoss] = useState<string>();

  const { find, observations } = useObservations(({ observations, find }) => ({
    observations,
    find,
  }));

  const sessionObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/**/plans/executive`),
    [find, observations, sessionId]
  );

  const globalObservations = useMemo(
    () => find(`/sessions/**/**/groups/**/plans/executive`),
    [find, observations]
  );

  const extractAvg = (list, key: string) => ({
    key,
    value: list.reduce((acc, o) => (acc += Number(o[key])), 0) / list.length,
  });

  const getAverageData = samples => {
    return [
      extractAvg(samples, 'lossByMissingActions'),
      extractAvg(samples, 'lossByMissingPUActions'),
      extractAvg(samples, 'lossByMissingPEOUAction'),
      extractAvg(samples, 'lossByEntropy'),
      extractAvg(samples, 'lossByNotFittingActions'),
    ];
  };

  const avgData = getAverageData(sessionObservations);
  const globalAvgData = getAverageData(globalObservations);

  const min = avgData.reduce((seed, item) => {
    return seed && seed.value < item.value ? seed : item;
  }, null);
  const max = avgData.reduce((seed, item) => {
    return seed && seed.value > item.value ? seed : item;
  }, null);

  const globalMin = globalAvgData.reduce((seed, item) => {
    return seed && seed.value < item.value ? seed : item;
  }, null);
  const globalMax = globalAvgData.reduce((seed, item) => {
    return seed && seed.value > item.value ? seed : item;
  }, null);

  return (
    <DebriefingSection title={t('slide-3-title')} subTitle={t('slide-3-desc')}>
      <PerformanceAdjustmentFilters
        hoveredLoss={hoveredLoss}
        displayedLosses={displayedLosses}
        handleUpdate={setDisplayedLossed}
        handleHover={(loss: string) => {
          if (displayedLosses.includes(loss)) setHoveredLoss(loss);
          else setHoveredLoss(undefined);
        }}
        handleLeave={() => setHoveredLoss(undefined)}
      />
      <PerformanceAdjustment
        data={sessionObservations as any[]}
        hoveredLoss={hoveredLoss}
        displayedLosses={displayedLosses}
        ownGroupId={ownGroupId}
        handleHover={(loss: string) => {
          if (displayedLosses.includes(loss)) setHoveredLoss(loss);
          else setHoveredLoss(undefined);
        }}
        handleLeave={() => setHoveredLoss(undefined)}
      />

      <div className="flex flex-col space-y-4">
        <div className="flex flex-col p-2 xl:p-4 my-auto mr-4 rounded-lg shadow-2xl right-8 bg-white/40 border-t-2 border-white backdrop-blur-sm shadow-black/5 min-w-max">
          <p className="mb-4 font-light text-slate-600 text-center uppercase">
            {t('this-session')}
          </p>

          <span className="ml-4 mb-4">
            <p className="font-bold uppercase text-xs xl:text-sm text-slate-700">
              {t('less-impact-variable')} <br />{' '}
            </p>
            <p className="font-light text-sm xl:text-base text-slate-900">
              {t(`${min.key}-title`)} : {Math.round(min.value * 100)}%
            </p>
          </span>

          <span className="ml-4 my-4">
            <p className="font-bold uppercase text-xs xl:text-sm text-slate-700">
              {t('most-impact-variable')} <br />{' '}
            </p>
            <p className="font-light text-sm xl:text-base text-slate-900">
              {t(`${max.key}-title`)} : {Math.round(max.value * 100)}%
            </p>
          </span>
        </div>

        <div className="flex flex-col p-2 xl:p-4 my-auto mr-4 rounded-lg shadow-2xl right-8 bg-white/40 border-t-2 border-white backdrop-blur-sm shadow-black/5 min-w-max">
          <p className="mb-4 font-light text-slate-600 text-center uppercase">
            Benchmarks
          </p>

          <span className="ml-4 mb-4">
            <p className="font-bold uppercase text-xs xl:text-sm text-slate-700">
              {t('less-impact-variable')} <br />{' '}
            </p>
            <motion.p
              className="font-light text-sm xl:text-base text-slate-900"
              animate={{
                filter: areBenchmarksVisible ? 'blur(0px)' : 'blur(8px)',
              }}
            >
              {t(`${globalMin.key}-title`)} :{' '}
              {Math.round(globalMin.value * 100)}%
            </motion.p>
          </span>

          <span className="ml-4 my-4">
            <p className="font-bold uppercase text-xs xl:text-sm text-slate-700">
              {t('most-impact-variable')} <br />{' '}
            </p>
            <motion.p
              className="font-light text-sm xl:text-base text-slate-900"
              animate={{
                filter: areBenchmarksVisible ? 'blur(0px)' : 'blur(8px)',
              }}
            >
              {t(`${globalMax.key}-title`)} :{' '}
              {Math.round(globalMax.value * 100)}%
            </motion.p>
          </span>

          <hr className="mb-4" />

          <div
            className="flex flex-row items-center space-x-2 cursor-pointer justify-center"
            onClick={() => setBenchmarksVisible(_ => !_)}
          >
            <div
              className={clsx(
                'text-gray-500 border-2 h-6 w-6',
                areBenchmarksVisible
                  ? 'rounded-full border-orange-700 bg-orange-700'
                  : 'rounded-full border-orange-700'
              )}
              style={{
                filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
              }}
            />
            <span className="text-sm">{t('show-benchmarks')}</span>
          </div>
        </div>
      </div>
    </DebriefingSection>
  );
};

export default PerformanceAdjustmentSection;
