import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const config = {
  apiKey: 'AIzaSyBxUhgDhTlgpknWkrJOc3E27aMGWpE7mdk',
  authDomain: 'change-u-4716d.firebaseapp.com',
  projectId: 'change-u-4716d',
  storageBucket: 'change-u-4716d.appspot.com',
  messagingSenderId: '711439786826',
  appId: '1:711439786826:web:d6ecc65254617bbfe0d7b3',
};

export const fb = initializeApp(config);
export const db = getFirestore(fb);
