import { useEffect, useState } from 'react';

export default function useDPI(): number {
  const [dpi, setDpi] = useState<number>(1);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setDpi(window.devicePixelRatio || 1);

    function handleResize() {
      setDpi(window.devicePixelRatio || 1);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dpi;
}
