import KOTTER_STEPS from '../../data/kotter-steps-data';
import { useMessages } from '../../store/messages';
import buildAssetPath from '../../utils/buildAssetPath';

interface IProps {
  groupId: string;
  preparation: number;
  communication: number;
  execution: number;
}

const StepsQuality = ({
  groupId,
  preparation,
  communication,
  execution,
}: IProps) => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="my-4 text-xl font-bold text-blue-900">Team: {groupId}</p>
      <div className="flex flex-col w-3/4 space-y-8">
        <div className="grid w-full h-24 grid-cols-8 grid-rows-1 gap-1 rounded">
          {KOTTER_STEPS.map((step, index) => (
            <div
              key={index}
              className="flex items-center justify-center w-full text-xs font-semibold text-center rounded 3xl:text-base leadingtight 3xl:leading-tight bg-white/60 backdrop-blur-sm text-slate-900"
            >
              {index + 1}. {step.label}
            </div>
          ))}
        </div>
        <div className="grid w-full h-24 grid-cols-8 grid-rows-1 gap-1 rounded">
          <div className="flex flex-col items-center justify-center col-span-3 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Preparation</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-preparation-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {preparation?.toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-1 overflow-visible rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Communication</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-communication-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {communication?.toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-4 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Execution</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-execution-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {execution?.toFixed(0)}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsQuality;
