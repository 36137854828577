import create, { GetState, SetState } from 'zustand';
import { produce } from 'immer';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createStore(set, get),
});

interface IPrivate {
  teamInfo: {
    [teamId: string]: {
      teamId: string;
      visitedKotterSteps: string[];
    };
  };
}

const createStore = (set, get) => {
  return {
    teamInfo: {},

    getVisitedKotterSteps: (teamId: string) => {
      get().initializeTeamInfoIfNotFound(teamId);

      return get().teamInfo[teamId].visitedKotterSteps;
    },

    visitKotterStep: (teamId: string, kotterStep: string) => {
      get().initializeTeamInfoIfNotFound(teamId);

      set(
        produce((_: IPrivate) => {
          _.teamInfo[teamId].visitedKotterSteps = [
            ...new Set([
              ...get().teamInfo[teamId].visitedKotterSteps,
              kotterStep,
            ]),
          ];
        })
      );
    },

    initializeTeamInfoIfNotFound: (teamId: string) => {
      if (!get().teamInfo[teamId])
        set(
          produce((_: IPrivate) => {
            _.teamInfo[teamId] = initializeTeamSlot(teamId);
          })
        );
    },
  };
};

const initializeTeamSlot = (teamId: string) => ({
  teamId,
  visitedKotterSteps: [],
});

export const useDebriefing = create(createRootSlice);
