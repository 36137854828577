import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useState } from 'react';
import KOTTER_STEPS from '../../data/kotter-steps-data';
import { useMessages } from '../../store/messages';

const Bho = () => {
  const [isLewinVisible, setIsLewinVisible] = useState(false);
  const [isInequalityVisible, setInequalityVisible] = useState(false);
  const t = useMessages(({ print }) => print);

  return (
    <div>
      <div className="absolute bg-white/40 flex flex-row items-center space-x-4 px-4 py-2 rounded-xl top-[2px] left-0 text-slate-800/80 right-0 w-1/3 mx-auto">
        <p className="flex-grow ml-auto text-sm font-bold text-left uppercase text-slate-600">
          {t('misc-legend')}
        </p>

        <div
          className="flex flex-row items-center justify-between cursor-pointer"
          onClick={() => setIsLewinVisible(!isLewinVisible)}
        >
          <span
            className={clsx(
              'w-5 shadow h-6 rounded block bg-white',
              isLewinVisible && 'border-4 border-white bg-blue-400'
            )}
          />
          <p className="ml-4 leading-none text-xxs">{t('lewin-model')}</p>
        </div>

        <div
          className="flex flex-row items-center justify-between cursor-pointer"
          onClick={() => setInequalityVisible(!isInequalityVisible)}
        >
          <span
            className={clsx(
              'w-5 shadow h-6 rounded block bg-white',
              isInequalityVisible && 'border-4 border-white bg-blue-400'
            )}
          />
          <p className="ml-4 leading-none text-xxs">
            {t('logical-inequality')}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center max-h-full space-y-32">
        <motion.div
          className="relative grid w-5/6 grid-rows-1 gap-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: isLewinVisible ? 1 : 0 }}
          transition={{ duration: 1 }}
          style={{
            gridTemplateColumns: 'repeat(17, minmax(0, 1fr))',
          }}
        >
          <p className="absolute left-0 right-0 mx-auto text-xl text-center -top-16 text-blue-900/70">
            {t('lewin-model')}
          </p>
          <div className="relative flex items-center justify-center h-20 col-span-2 text-base font-medium text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            U
            <Juncture label="X" />
            <Label label="Unfreezing" />
          </div>
          <div className="relative flex items-center justify-center h-20 col-span-10 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            C
            <Juncture label="X" />
            <Label label="Change" />
          </div>
          <div className="relative flex items-center justify-center h-20 col-span-4 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            R
            <Label label="Refreezing" />
          </div>
        </motion.div>

        <div
          className="relative grid w-5/6 grid-rows-1 gap-2"
          style={{
            gridTemplateColumns: 'repeat(17, minmax(0, 1fr))',
          }}
        >
          <p className="absolute left-0 right-0 mx-auto text-xl text-center -top-8 text-blue-900/70">
            {t('kotter-model')}
          </p>
          {KOTTER_STEPS.map((step, stepIndex) => (
            <div
              key={step.id}
              className={clsx(
                'rounded shadow-xl leading-tight 3xl:leading-tight h-20 flex items-center justify-center font-bold text-xs 3xl:text-sm text-blue-900 text-center col-span-2',
                stepIndex <= 2
                  ? 'bg-blue-300'
                  : stepIndex === 3
                  ? 'bg-slate-300'
                  : 'bg-white'
              )}
            >
              {stepIndex + 1}. {step.label}
            </div>
          ))}
        </div>

        <motion.div
          className="relative grid w-5/6 grid-rows-1 gap-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: isInequalityVisible ? 1 : 0 }}
          transition={{ duration: 1 }}
          style={{
            gridTemplateColumns: 'repeat(17, minmax(0, 1fr))',
          }}
        >
          <p className="absolute left-0 right-0 mx-auto text-xl text-center -top-16 text-blue-900/70">
            {t('logical-inequality')}
          </p>
          <div className="relative flex items-center justify-center h-20 col-span-2 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            D
            <Juncture label="X" />
            <Label label="Dissatisfaction" />
          </div>
          <div className="relative flex items-center justify-center h-20 col-span-3 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            V
            <Juncture label="X" />
            <Label label="Vision" />
          </div>
          <div className="relative flex items-center justify-center h-20 col-span-11 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            P(F)
            <Juncture label=">" />
            <Label label="Plan or first steps" />
          </div>
          <div className="relative flex items-center justify-center h-20 col-span-1 text-base font-medium leading-tight text-blue-900 bg-white rounded shadow-xl 3xl:text-xl">
            R
            <Label label="Resistance" />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const Juncture = ({ label }: { label: string }) => (
  <span className="absolute z-20 flex items-center justify-center w-10 h-10 p-1 text-xs font-black text-center text-white border-2 border-white rounded-full shadow-lg bg-slate-300 -right-6 font-bolx">
    {label}
  </span>
);

const Label = ({ label }: { label: string }) => (
  <span className="absolute left-0 right-0 mx-auto text-sm text-center text-slate-500/90 -top-6">
    {label}
  </span>
);

export default Bho;
