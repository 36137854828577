import { motion } from 'framer-motion';
import clsx from 'clsx';

interface IProps {
  index: number;
  isHighlighted?: boolean;
  label: string;
  description: string;
  descriptionOrientation?: string;
  layoutAnimationKey: string;
  hideWhenIdle?: boolean;
  isObscured?: boolean;
  isUnused?: boolean;
  keepHighlighted?: boolean;
  handleMouseEnter?: () => void;
  handleTitleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  hasBeenRevealed?: boolean;
  badge?: string;
  outline?: string;
  color?: string;
  isRevealed?: boolean;
}

const Card = ({
  index,
  isHighlighted,
  label,
  description,
  descriptionOrientation,
  layoutAnimationKey,
  hideWhenIdle,
  isObscured,
  isUnused,
  keepHighlighted,
  handleMouseEnter,
  handleTitleMouseEnter,
  handleMouseLeave,
  hasBeenRevealed,
  badge,
  outline,
  color,
  isRevealed,
}: IProps) =>
  isObscured ? (
    <motion.span
      layoutId={`giardo-${layoutAnimationKey}-${index}`}
      key={`dark-${layoutAnimationKey}-${index}`}
      className="w-12 xl:w-14 2xl:w-16 3xl:w-[4.2rem] 4xl:w-20 h-[4.5rem] 2xl:h-20 3xl:h-24 4xl:h-28 rounded relative bg-slate-400/40 overflow-hidden flex items-center justify-center"
    >
      <p className="absolute font-thin text-9xl opacity-5 text-slate-600">X</p>
    </motion.span>
  ) : (
    <motion.span
      layoutId={`giardo-${layoutAnimationKey}-${index}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={`${layoutAnimationKey}-${index}`}
      className={clsx(
        'w-10 xl:w-12 2xl:w-14 3xl:w-[4.2rem] 4xl:w-20 h-[4.5rem] 2xl:h-20 3xl:h-24 4xl:h-28 p-1 text-blue-900 rounded flex items-center justify-center relative',
        isHighlighted
          ? 'bg-yellow-200 text-base leading-none shadow-xl shadow-black/10'
          : `${
              keepHighlighted && isRevealed ? 'bg-yellow-100' : 'bg-white'
            } text-xs shadow-lg shadow-black/5 ${
              hideWhenIdle && !isRevealed && 'blur-[2px]'
            }`,
        {
          'border-2 border-red-400': outline === 'red',
          'border-2 border-blue-400': outline === 'blue',
        }
      )}
      animate={{
        scale: isHighlighted ? 1.2 : 1,
        zIndex: isHighlighted ? 2 : 1,
        opacity: isUnused ? 0.4 : 1,
      }}
      style={{
        backgroundColor: !isHighlighted ? color : undefined,
      }}
    >
      {badge && (
        <span
          className={clsx(
            'px-1 py-px text-white font-bold uppercase rounded absolute -bottom-1 -right-1',
            badge === 'PU' ? 'bg-indigo-400' : 'bg-sky-400'
          )}
        >
          {badge}
        </span>
      )}

      <p
        className={clsx('text-xs', isHighlighted ? 'font-normal' : 'font-bold')}
      >
        {label}
      </p>

      {description && (
        <>
          <p
            onMouseOver={handleTitleMouseEnter}
            className={clsx(
              'absolute w-20 3xl:w-24 4xl:w-28 mx-auto text-xxs 3xl:text-xs 4xl:text-sm 5xl:text-base leading-none 3xl:leading-none 4xl:leading-none 5xl:leading-none text-center subpixel-antialiased transition-all duration-200 ease-in-out',
              isHighlighted
                ? 'opacity-90'
                : hideWhenIdle
                ? hasBeenRevealed
                  ? 'opacity-60'
                  : 'opacity-0'
                : 'opacity-60',
              descriptionOrientation === 'top' &&
                (index % 2 === 0 ? '-top-16' : '-top-32'),
              descriptionOrientation === 'bottom' &&
                (index % 2 === 0 ? '-bottom-16' : '-bottom-32')
            )}
          >
            {description}
          </p>
          <span
            className={clsx(
              'absolute w-px mx-auto bg-blue-400/60',
              descriptionOrientation === 'top' &&
                (index % 2 === 0 ? '-top-4 h-4' : '-top-20 h-20'),
              descriptionOrientation === 'bottom' &&
                (index % 2 === 0 ? '-bottom-4 h-4' : '-bottom-20 h-20')
            )}
            style={{
              display: isHighlighted
                ? 'block'
                : hideWhenIdle
                ? hasBeenRevealed
                  ? 'block'
                  : 'none'
                : 'block',
            }}
          />
        </>
      )}
    </motion.span>
  );

export default Card;
