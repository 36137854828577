import { motion } from 'framer-motion';
import { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { scenarioLabels } from './scenario-labels';

const labels = {
  lossByEntropy: 'Entropia',
  lossByNotFittingActions: 'No fit',
  lossByMissingPEOUAction: 'PEOU',
  lossByMissingPUActions: 'PU',
  lossByMissingActions: 'Azioni mancanti',
};

const AcceptanceRateLabel = ({ x, y, width, height, value, budgetSpent }) => {
  const shortestDimension: number = Math.min(height, width - 42);
  const minimumTextSize: number = 8;
  const maximumTextSize: number = 42;
  const textSize = Math.min(
    Math.max(shortestDimension - 8, minimumTextSize),
    maximumTextSize
  );

  const verticalPadding: number = height > width ? height / 4 : height / 6;
  const alignTop: number = y + verticalPadding;
  const alignBottom: number = y + (height - verticalPadding);
  const verticalSpacing: number = textSize * 0.7 + 4;

  return (
    <g>
      <text
        x={x + width / 2}
        y={alignTop}
        fill="White"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontFamily="Inter"
        fontSize={`${textSize * 0.7}px`}
        style={{
          filter:
            'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))',
        }}
      >
        AcR
      </text>
      <text
        x={x + width / 2}
        y={alignTop + verticalSpacing}
        fill="White"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontFamily="Inter"
        fontSize={`${textSize}px`}
        style={{
          filter:
            'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))',
        }}
      >
        {Math.round(value)}%
      </text>
      <text
        x={x + width / 2}
        y={alignBottom - verticalSpacing}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontFamily="inter"
        fontSize={`${textSize * 0.7}px`}
        style={{
          filter:
            'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))',
        }}
      >
        Budget
      </text>
      <text
        x={x + width / 2}
        y={alignBottom}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontFamily="inter"
        fontSize={`${textSize}px`}
        style={{
          filter:
            'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))',
        }}
      >
        {Math.round(budgetSpent)}%
      </text>
    </g>
  );
};

const LossLabel = ({ x, y, width, height, value }) => {
  const shortestDimension: number = Math.min(height, width - 42);
  const minimumTextSize: number = 8;
  const maximumTextSize: number = 42;
  const isBullshitScreen: boolean =
    typeof window !== 'undefined' && window.innerWidth < 1300;
  const textSize: number =
    Math.min(
      Math.max(shortestDimension - 8, minimumTextSize),
      maximumTextSize
    ) * (isBullshitScreen ? 0.7 : 1);

  return (
    <g className="pointer-events-none">
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="White"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontFamily="Inter"
        fontSize={`${textSize}px`}
        style={{
          filter:
            'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))',
        }}
      >
        {Math.round(value)}%
      </text>
    </g>
  );
};

interface IProps {
  handleHover(loss: string): void;
  handleLeave(): void;
  hoveredLoss: string;
  displayedLosses: string[]; // lossByEntropy; lossByMissingActions; lossByMissingPUActions; lossByMissingPEOUAction;
  data: {
    acceptanceRate: number;
    lossByMissingActions: number;
    lossByMissingPUActions: number;
    lossByMissingPEOUAction: number;
    lossByEntropy: number;
    lossByNotFittingActions: number;
    scenario: string;
    groupId: string;
    normalizedBudgetSpent: number;
  }[];
  ownGroupId?: string;
}

const PerformanceAdjustment = ({
  hoveredLoss,
  displayedLosses,
  data,
  ownGroupId,
  handleHover,
  handleLeave,
}: IProps) => {
  const transformedData = useMemo(
    () =>
      data
        .filter(d => Object.keys(labels).every(key => !isNaN(d[key])))
        .sort((a, b) => a.groupId.localeCompare(b.groupId))
        .map(d => ({
          acceptanceRate: d.acceptanceRate * 100,
          lossByMissingActions: d.lossByMissingActions * 100,
          lossByMissingPUActions: d.lossByMissingPUActions * 100,
          lossByMissingPEOUAction: d.lossByMissingPEOUAction * 100,
          lossByEntropy: d.lossByEntropy * 100,
          lossByNotFittingActions: d.lossByNotFittingActions * 100,
          normalizedBudgetSpent: d.normalizedBudgetSpent * 100,
          scenario: d.scenario,
          groupId: d.groupId === ownGroupId ? `${d.groupId} (you)` : d.groupId,
          isOwnGroup: d.groupId === ownGroupId,
        })),
    [data]
  );

  const getScenario = (groupId: string) => {
    const name = data.find(d => d.groupId === groupId)?.scenario;
    return {
      name: scenarioLabels[name] || name,
      color: name === 'neutral' ? 'rgb(37, 99, 235)' : 'rgb(147, 51, 234)',
    };
  };

  return (
    <div className="w-full h-full rounded-lg overflow-hidden">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={transformedData}
          margin={{
            top: 80,
            right: 80,
            left: 80,
            bottom: 80,
          }}
        >
          <CartesianGrid strokeDasharray="8 8" />
          <XAxis
            dataKey="groupId"
            label={{
              value: 'Teams',
              dy: 32,
              fontWeight: 600,
              fontSize: 12,
              opacity: 0.9,
            }}
            tick={props => {
              const scenario = getScenario(props.payload.value);

              return (
                <g {...props}>
                  <text {...props}>
                    <tspan x={props.x} dy="1em">
                      {props.payload.value}
                    </tspan>
                    <tspan
                      fontSize="0.7em"
                      x={props.x}
                      dy="1.1em"
                      fill={scenario.color}
                    >
                      {scenario.name}
                    </tspan>
                  </text>
                </g>
              );
            }}
          />
          <YAxis
            domain={[0, 100]}
            ticks={[0, 50, 100]}
            unit="%"
            label={{
              value: 'AcR',
              dx: -32,
              angle: -90,
              fontWeight: 600,
              fontSize: 12,
              opacity: 0.9,
            }}
          />

          <Bar
            dataKey="acceptanceRate"
            stackId="scenario"
            label={props => (
              <AcceptanceRateLabel
                {...props}
                value={transformedData[props.index]?.acceptanceRate}
                budgetSpent={transformedData[props.index].normalizedBudgetSpent}
              />
            )}
          >
            {transformedData.map(({ scenario }, index) => (
              <Cell
                key={`cell-${index}`}
                fill={scenario === 'normal' ? '#82c89d' : '#c8b0d2'}
              />
            ))}
          </Bar>

          {Object.keys(labels).map(loss => (
            <Bar
              key={loss}
              dataKey={loss}
              stackId="scenario"
              label={props =>
                displayedLosses?.includes(loss) ? (
                  <LossLabel
                    {...props}
                    value={transformedData[props.index][loss]}
                  />
                ) : null
              }
              fill={displayedLosses?.includes(loss) ? '#c9c9f9' : '#b9b9f9'}
              shape={props => (
                <CustomBar
                  {...props}
                  highlighted={hoveredLoss === loss}
                  revealed={displayedLosses?.includes(loss)}
                  onMouseOver={() => handleHover(loss)}
                  onMouseLeave={() => handleLeave()}
                />
              )}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomBar = props => {
  const offset: number = props.highlighted || props.revealed ? 4 : 0;
  const minHeight: number = 1;

  return (
    <g
      onMouseOver={() => props.onMouseOver()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <motion.rect
        width={props.width - offset}
        height={(props.height || minHeight + offset) - offset}
        fill={props.fill}
        x={props.x + offset / 2}
        y={props.y + offset / 2}
        stroke={props.highlighted ? 'orange' : 'transparent'}
        strokeWidth={offset}
      />
      <motion.rect
        width={props.width - offset * 2}
        height={(props.height || minHeight) - offset * 2}
        fill={props.fill}
        x={props.x + offset}
        y={props.y + offset}
        className="cursor-pointer"
        whileHover={{
          scale: 1.04,
          filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 200, 0.1))',
        }}
        animate={{ scale: 1, filter: 'none' }}
      >
        {props.children}
      </motion.rect>
    </g>
  );
};

export default PerformanceAdjustment;
