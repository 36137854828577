const KOTTER_STEPS = Object.freeze([
  { id: 'urgency', label: 'Urgency' },
  { id: 'coalition', label: 'Coalition' },
  { id: 'vision-and-strategy', label: 'Vision, strategy and plan' },
  { id: 'communicate', label: 'Communicate' },
  { id: 'empower-action', label: 'Empower action' },
  { id: 'short-term-wins', label: 'Short term wins' },
  { id: 'consolidate-gains', label: 'Consolidate gains' },
  { id: 'anchor-change', label: 'Anchor change' },
]);

export default KOTTER_STEPS;
